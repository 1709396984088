@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.slider {
  overflow: hidden;

  &__inner {
    position: relative;
  }

  &__slides {
    overflow: visible;
    max-width: 95vw;
  }

  &__items {
    max-width: inherit;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: self-end;
    max-width: inherit;
    min-height: 616px;
    height: auto;
    padding: clamp(12px, 1.7vw, 24px);
    overflow: hidden;
    border-radius: var(--radius-l);

    &:not(.swiper-slide-active) {
      opacity: 0;
    }
  }

  &__image {
    position: absolute;
    inset: 0;
    height: 100%;
  }

  &__content {
    position: relative;
    z-index: 1;
    max-width: 625px;
    width: 100%;
    padding: 16px clamp(24px, 3.4vw, 48px);
    background-color: var(--color-white-430);
    border-radius: var(--radius-l);

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: inherit;
      backdrop-filter: blur(5px);
      z-index: -1;
    }

    h3,
    p {
      color: var(--color-white);
    }

    h3 {
      margin-bottom: 4px;
      font-size: clamp(18px, 1.4vw, 20px);
      font-weight: var(--fw-700);
    }

    p {
      font-size: 14px;
      font-weight: var(--fw-500);
      line-height: 1.43;
    }
  }

  &__buttons {
    @include flex-all-center;

    gap: 40px;
  }

  &__button {
    @include cover-background;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    width: 36px;
    height: 36px;
    background-color: var(--color-white-80);
    background-image: url("../images/icons/arrow-icon.svg");
    border: 1px solid var(--color-grey-416);
    border-radius: var(--radius-full);

    &--next {
      right: -18px;
      transform: translateY(-50%) rotate(180deg);
    }

    &--prev {
      left: -18px;
    }

    &.swiper-button-disabled {
      opacity: 0.25;
      border-color: var(--color-dark-60);
    }
  }

  @include media(desktop) {
    &__button {
      &--prev {
        left: 24px;
      }

      &--next {
        right: 24px;
      }
    }
  }

  @include media(tablet) {
    &__item {
      min-height: 400px;
    }

    &__button {
      width: 24px;
      height: 24px;
    }
  }

  @include media(mobile-m) {
    &__items {
      margin-bottom: 24px;
    }

    &__item {
      align-items: center;
    }

    &__content {
      margin-inline: auto;
    }

    &__button {
      position: static;
      transform: translateY(0);

      &--next {
        transform: rotate(180deg);
      }
    }
  }
}