@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: clamp(24px, 2.8vw, 40px) 0;
  background-color: var(--color-green-400);

  &__wrapper {
    @include flex-all-sb;
  }

  &__mail {
    position: relative;
    padding-left: 32px;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url("../images/icons/mail-icon.svg");
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
    }
  }

  @include media(mobile-l) {
    &__wrapper {
      flex-direction: column;
      gap: 24px;
    }
  }
}