@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: block;
  width: fit-content;
  margin: 0;
  padding: 7px clamp(15px, 2.3vw, 31px);
  border: 1px solid var(--color-white);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-dark);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-main);
  background: var(--color-white);
  transition-property: background, color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &:hover,
  &--reverse,
  &.selected.tabs__btn {
    color: var(--color-white);
    background: var(--color-red-400);
    border-color: var(--color-red-400);
  }

  &--reverse {
    &:hover {
      color: var(--color-red-400) !important;
      background: var(--color-white);
    }
  }

  &--large {
    padding: 11px clamp(24px, 4.5vw, 63px);
  }

  &--centered {
    margin-inline: auto;

    @include media(mobile-m) {
      width: 100%;
    }
  }

  &--send {
    padding: 4px 24px 4px 44px;
    border-radius: var(--radius-xs);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      background-image: url("../images/icons/send-icon.svg");
    }

    @include media(mobile-m) {
      border-radius: var(--radius-s);
      padding-block:  clamp(10px, 2vw, 21.5px);
      padding-inline: 16px;
      font-size: 14px;

      &::before {
        left: 12px;
      }
    }
  }

  &.tabs__btn {
    padding: 8px clamp(12px, 1.7vw, 24px);
    font-weight: var(--fw-600);
    line-height: 1.25;
    color: var(--color-grey-400);
    cursor: pointer;
  }
}