@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: calc(var(--header-height) + 64px) 0 rem(60);
    min-height: 764px;
    background-position: 100% 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    @include flex-v-center;

    flex-direction: column;
    gap: 24px;
    position: relative;
    z-index: 2;
    margin-inline: auto;
    text-align: center;
  }

  &--home {
    .hero__content {
      max-width: 1024px;
      width: 100%;

      .subtitle {
        width: 55%;
      }
    }
  }

  &--request {
    .hero__inner {
      min-height: 600px;
      padding: 0;
    }

    .hero__content {
      gap: 0;
      padding-bottom: 40px;

      h1 {
        margin-bottom: 30px;
        font-family: var(--font-family-primary);
        font-size: clamp(20px, 2vi, 28px);
        line-height: 1;
        font-weight: var(--fw-500);
      }

      p {
        font-weight: var(--fw-500);
        color: var(--color-white);
      }
    }
  }

  &--page {
    .hero__content {
      .subtitle {
        width: 45%;
      }
    }
  }

  &--guide {
    .hero__content {
      width: 90%;
    }
  }

  @include media(tablet) {
    &__inner,
    &--request .hero__inner {
      min-height: 480px;
    }

    &--request {
      .hero__inner {
        padding: 32px 0;
      }

      .hero__content {
        padding-bottom: 0;
      }
    }
  }

  @include media(mobile-m) {
    &__content {
      .subtitle {
        width: 100%;
      }
    }


  &--page {
    .hero__content {
      .subtitle {
        width: 100%;
      }
    }
  }
  }
}