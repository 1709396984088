@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.text {
  h2 {
    font-family: var(--font-family-primary);
    font-weight: var(--fw-600);
    font-size: clamp(22px, 2.3vw, 32px);
  }

  h3:not(.accent),
  p {
    color: var(--color-green-500);
    opacity: 0.8;
  }

  &__wrapper {
    max-width: 1091px;
    width: 100%;
    margin-inline: auto;

    & > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__image {
    border-radius: var(--radius-m);
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__desc {
    text-align: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(12px, 1.7vw, 24px);
  }

  &__items {
    @include flex-sb;
    flex-direction: column;
    gap: 24px;
  }

  &__item {
    h3 {
      margin-bottom: 16px;
    }
  }

  &__flex {
    @include flex-all-sb;
    gap: 24px;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &__content {
    & > *:not(:last-child, h2) {
      margin-bottom: 24px;
    }

    h2 {
      margin-bottom: clamp(24px, 2.5vw, 36px);
    }
  }

  ul:not([class]) {
    padding-left: 24px;
  }

  li:not([class]) {
    display: list-item;
    list-style: disc;

    &::marker {
      font-size: 10px;
    }

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  &--list {
    .text__content {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__flex {
      flex-direction: column;
    }

    &__image {
      width: 100%;

      img {
        aspect-ratio: 4/2;
      }
    }

    &--list {
      .text__content {
        ul {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}