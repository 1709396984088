@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  h1 {
    margin-bottom: clamp(24px, 3.4vw, 48px);
    font-size: clamp(26px, 2.8vi, 40px);
    line-height: 1.42;
    color: var(--color-red-400);
  }

  h2 {
    font-family: var(--font-family-primary);
    font-weight: var(--fw-600);
    font-size: clamp(22px, 2.3vw, 32px);
    margin-bottom: 24px;
  }

  .policy__content:not(:last-child) {
    margin-bottom: clamp(24px, 3.4vw, 48px);;
  }

  .policy__content > *:not(:last-child, h2) {
    margin-bottom: clamp(24px, 3vw, 32px);
  }

  ul {
    padding-left: clamp(16px, 3vw, 32px);
  }

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: "\2714";
      position: absolute;
      left: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  a {
    color: var(--color-red-400);
  }

  strong {
    font-size: clamp(18px, 3vw, 22px);
    font-weight: var(--fw-500);
  }
}