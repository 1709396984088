@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.section {
  padding: clamp(30px, 4.5vw, 65px) 0;

  &__inner > *:not(:last-child) {
    margin-bottom: clamp(24px, 3.4vw, 45px);
  }

  &__top {
    @include flex-v-center;
    flex-direction: column;
    gap: 12px;
    text-align: center;
  }

  &__wrapper {
    max-width: 866px;
    width: 100%;
    margin-inline: auto;
  }

  &__image {
    margin-bottom: 28px;
    border-radius: var(--radius-l);
  }

  &__intro {
    margin-bottom: 22px;
  }

  &--accent {
    background-color: var(--color-grey-100);
  }

  &--explore {
    .section__top {
      align-items: flex-start;
      max-width: 978px;
      width: 100%;
      margin-inline: auto 0;
      text-align: left;
    }
  }

  @include media(mobile-l) {
    &__image {
      aspect-ratio: 3/2;
    }
  }
}