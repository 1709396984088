@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.card {
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: clamp(24px, 3.4vw, 48px) clamp(12px, 1.7vw, 24px);
  }

  &__item {
    background-color: var(--color-white);
    border-radius: var(--radius-l);
    box-shadow: var(--shadow-primary);
  }

  &__image {
    border-top-left-radius: var(--radius-l);
    border-top-right-radius: var(--radius-l);
    border-bottom-left-radius: var(--radius-m);
    border-bottom-right-radius: var(--radius-m);
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__content {
    padding: 24px clamp(12px, 1.7vw, 24px);

    h3 {
      margin-bottom: 6px;
      color: var(--color-green-400);
    }

    .accent {
      margin-bottom: 16px;
    }
  }

  &__text {
    & > p {
      margin-bottom: 15px;
    }

    ul {
      padding-left: 24px;
    }

    li {
      display: list-item;
      list-style: disc;

      &::marker {
        font-size: 10px;
      }

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  &--three {
    .card__items {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--small {
    .card__items {
      grid-template-columns: repeat(2, 1fr);
      gap: clamp(12px, 1.7vw, 22px) clamp(24px, 3.4vw, 48px);
    }

    .card__item {
      border-radius: var(--radius-m);
    }

    .card__content {
      padding: 16px clamp(12px, 1.7vw, 24px);

      h3 {
        margin-bottom: 18px;
      }
    }
  }

  &--welcome {
    .card__items {
      margin-bottom: 24px;
    }
  }

  &--btn {
    .card__item {
      position: relative;
      padding-bottom: 78px;
    }

    .card__content {
      padding-bottom: 0;

      *[class].btn {
        position: absolute;
        bottom: 22px;
        width: 89%;
        border-radius: var(--radius-s);
      }
    }
  }

  @include media(tablet) {
    &__image {
      img {
        aspect-ratio: 1/1;
      }
    }
  }

  @include media(mobile-l) {
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }

    &--three {
      .card__items {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
      }

      .card__item {
        &:last-of-type {
          grid-column: span 2;

          img {
            aspect-ratio: 4/2;
          }
        }
      }
    }
  }

  @include media(mobile-m) {
    &__items {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    &__image {
      img {
        aspect-ratio: 4/2;
      }
    }

    &--three,
    &--small {
      .card__items {
        grid-template-columns: 1fr;
      }
    }

    &--three {
      .card__item {
        &:last-of-type {
          grid-column: auto;
        }
      }
    }

    &--btn {
      .card__item {
        padding-bottom: 24px;
      }

      .card__content {
        *[class].btn {
          position: static;
          width: 100%;
        }
      }

      .card__text {
        margin-bottom: 24px;
      }
    }
  }
}