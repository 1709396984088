@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.0125em;
  color: var(--color-grey-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  &--no-hero {
    padding-top: var(--header-height);
  }
}

.article {
  position: relative;

  &__link::before {
    content: "";
    position: absolute;
    z-index: 2;
    inset: 0;
  }
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
    background: var(--gradient-primary);
  }

  &--hero::before {
    background: var(--color-dark-60);
  }

  &--contact::before {
    background: var(--gradient-contact);
  }

  &--slider::before {
    background: var(--gradient-slider);
  }
}

.form {
  position: relative;
  max-width: 650px;
  width: 100%;
  margin-inline: auto;
  margin-bottom: 12px;

  *[class].btn {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }

  @include media(mobile-m) {
    display: flex;
    flex-direction: column;
    gap: 12px;

    *[class].btn {
      position: initial;
      transform: translateY(0);
      width: 100%;
    }
  }
}

label {
  position: relative;
  display: block;

  &::before {
    @include cover-background;

    content: "";
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/mail-icon.svg");
  }

  @include media(mobile-l) {
   &::before {
     left: 12px;
   }
  }
}

input {
  display: block;
  width: 100%;
  padding-inline: 58px 190px;
  padding-block:  clamp(10px, 2vw, 21.5px);
  background-color: var(--color-white-90);
  border-radius: var(--radius-s);
  box-shadow: var(--shadow-contact);
  font-size: 16px;
  line-height: 1;
  color: var(--color-green-500);

  &::placeholder {
    font-size: 14px;
    color: var(--color-grey-400);
  }

  @include media(mobile-l) {
    padding-inline: 40px 16px;
  }
}