@import "https://fonts.googleapis.com/css2?family=Merriweather:wght@700;900&family=Poppins:wght@400;500;600;700&display=swap";
:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Merriweather", serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-900: 900;
  --color-white: #fff;
  --color-white-80: #fffc;
  --color-white-90: #ffffffe5;
  --color-black: #000;
  --color-dark: #252b42;
  --color-dark-60: #00070599;
  --color-green-400: #004830;
  --color-green-500: #072711;
  --color-grey-100: #f9f9f9;
  --color-grey-300: #e6e6e6;
  --color-grey-400: #414141;
  --color-grey-416: #41414129;
  --color-white-430: #a197974d;
  --color-red-400: #f14a46;
  --radius-main: 99px;
  --radius-l: 40px;
  --radius-m: 16px;
  --radius-s: 12px;
  --radius-xs: 4px;
  --radius-full: 50%;
  --shadow-primary: 0px 2px 25px 0px #0048301a;
  --shadow-secondary: 0px 2px 4px 0px #0000001a;
  --shadow-contact: 0px 13px 19px 0px #00000012;
  --gradient-primary: linear-gradient(0deg, #ffffff14, #ffffff14), linear-gradient(180deg, #cecfd000 77.06%, #fffc 100%);
  --gradient-slider: linear-gradient(180deg, #fdfaf500 79.25%, #fffc 100%);
  --gradient-contact: linear-gradient(0deg, #ffffff4d, #ffffff4d), linear-gradient(0deg, #0003, #0003);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: .0125em;
  color: var(--color-grey-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: 16px;
  font-style: normal;
  line-height: 1.25;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  display: grid;
}

.main--no-hero {
  padding-top: var(--header-height);
}

.article {
  position: relative;
}

.article__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient-primary);
  position: absolute;
  inset: 0;
}

.overlay--hero:before {
  background: var(--color-dark-60);
}

.overlay--contact:before {
  background: var(--gradient-contact);
}

.overlay--slider:before {
  background: var(--gradient-slider);
}

.form {
  max-width: 650px;
  width: 100%;
  margin-inline: auto;
  margin-bottom: 12px;
  position: relative;
}

.form [class].btn {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 576px) {
  .form {
    flex-direction: column;
    gap: 12px;
    display: flex;
  }

  .form [class].btn {
    position: initial;
    width: 100%;
    transform: translateY(0);
  }
}

label {
  display: block;
  position: relative;
}

label:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/mail-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {
  label:before {
    left: 12px;
  }
}

input {
  width: 100%;
  background-color: var(--color-white-90);
  border-radius: var(--radius-s);
  box-shadow: var(--shadow-contact);
  color: var(--color-green-500);
  padding-block: clamp(10px, 2vw, 21.5px);
  padding-inline: 58px 190px;
  font-size: 16px;
  line-height: 1;
  display: block;
}

input::placeholder {
  color: var(--color-grey-400);
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  input {
    padding-inline: 40px 16px;
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  overflow: hidden;
}

h1, h2 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-700);
}

h1 {
  color: var(--color-white);
  font-size: clamp(26px, 4.5vi, 64px);
  line-height: 1.25;
}

h2 {
  color: var(--color-green-500);
  font-size: clamp(26px, 2.8vi, 40px);
  line-height: 1.42;
}

h3 {
  font-size: 18px;
  font-weight: var(--fw-600);
  letter-spacing: 0;
  color: var(--color-red-400);
  line-height: 1.5;
}

.subtitle {
  font-size: clamp(20px, 2vi, 24px);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-white-80);
  line-height: 1.38;
}

.desc {
  font-size: 18px;
  font-weight: var(--fw-500);
  color: var(--color-grey-400);
  line-height: 1.11;
}

.accent {
  font-weight: var(--fw-600);
  color: var(--color-red-400);
}

.decor {
  padding-top: 26px;
  position: relative;
}

.decor:before {
  content: "";
  width: 100%;
  border-top: 2px solid var(--color-grey-300);
  position: absolute;
  top: -2px;
  left: 0;
}

a, button {
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
}

p {
  line-height: 1.25;
}

.footer__text {
  letter-spacing: 0;
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.5;
}

.copyright {
  letter-spacing: -.01em;
  text-align: center;
}

.copyright br {
  display: none;
}

@media only screen and (max-width: 576px) {
  .copyright br {
    display: block;
  }
}

.header {
  z-index: 10;
  max-width: var(--content-width);
  width: 100%;
  background-color: var(--color-green-400);
  border-radius: var(--radius-main);
  padding: clamp(10px, 1.3vw, 16px) clamp(12px, 4.6vw, 64px);
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.header.hide {
  transform: translateY(calc(-100% - 24px))translateX(-50%);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: 306px;
  width: 100%;
  margin-right: 2.25rem;
}

.nav__inner {
  max-width: 612px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: -1px;
    top: calc(0px - var(--header-height));
    z-index: -1;
    padding: calc(70px + var(--header-height) * 1) 16px 30px;
    max-width: unset;
    width: calc(100% + 1px);
    height: calc(var(--vh)  + 24px);
    background-color: var(--color-green-400);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: clamp(12px, 1.7vw, 24px);
}

.nav__link {
  font-size: 18px;
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-white);
  text-transform: capitalize;
  line-height: 1.5;
  transition: border-bottom .3s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  border-bottom: 2px solid var(--color-white);
}

.nav__btns {
  align-items: center;
  gap: 16px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }

  .nav:not(.nav--footer) .logo {
    max-width: 240px;
  }
}

.nav:not(.nav--footer) .nav__list {
  margin: 0 2.25rem 0 0;
}

@media only screen and (max-width: 992px) {
  .nav:not(.nav--footer) .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav:not(.nav--footer) .nav__item:not(:last-of-type) {
  margin-right: 2.25rem;
}

@media only screen and (max-width: 992px) {
  .nav:not(.nav--footer) .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav:not(.nav--footer) .nav__item {
    margin-bottom: 30px;
  }
}

.nav--footer {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

@media only screen and (max-width: 992px) {
  .nav--footer .logo {
    max-width: 180px;
    margin-right: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .nav--footer {
    flex-direction: column;
    gap: 24px;
  }

  .nav--footer .logo {
    max-width: 260px;
  }
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }
}

[class].btn {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--color-white);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-dark);
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  border-radius: var(--radius-main);
  background: var(--color-white);
  margin: 0;
  padding: 7px clamp(15px, 2.3vw, 31px);
  font-size: 18px;
  line-height: 1.5;
  transition-property: background, color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: block;
}

[class].btn:hover, [class].btn--reverse, [class].btn.selected.tabs__btn {
  color: var(--color-white);
  background: var(--color-red-400);
  border-color: var(--color-red-400);
}

[class].btn--reverse:hover {
  background: var(--color-white);
  color: var(--color-red-400) !important;
}

[class].btn--large {
  padding: 11px clamp(24px, 4.5vw, 63px);
}

[class].btn--centered {
  margin-inline: auto;
}

@media only screen and (max-width: 576px) {
  [class].btn--centered {
    width: 100%;
  }
}

[class].btn--send {
  border-radius: var(--radius-xs);
  padding: 4px 24px 4px 44px;
}

[class].btn--send:before {
  content: "";
  width: 21px;
  height: 21px;
  background-image: url("../images/icons/send-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 576px) {
  [class].btn--send {
    border-radius: var(--radius-s);
    padding-block: clamp(10px, 2vw, 21.5px);
    padding-inline: 16px;
    font-size: 14px;
  }

  [class].btn--send:before {
    left: 12px;
  }
}

[class].btn.tabs__btn {
  font-weight: var(--fw-600);
  color: var(--color-grey-400);
  cursor: pointer;
  padding: 8px clamp(12px, 1.7vw, 24px);
  line-height: 1.25;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.section {
  padding: clamp(30px, 4.5vw, 65px) 0;
}

.section__inner > :not(:last-child) {
  margin-bottom: clamp(24px, 3.4vw, 45px);
}

.section__top {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

.section__wrapper {
  max-width: 866px;
  width: 100%;
  margin-inline: auto;
}

.section__image {
  border-radius: var(--radius-l);
  margin-bottom: 28px;
}

.section__intro {
  margin-bottom: 22px;
}

.section--accent {
  background-color: var(--color-grey-100);
}

.section--explore .section__top {
  max-width: 978px;
  width: 100%;
  text-align: left;
  align-items: flex-start;
  margin-inline: auto 0;
}

@media only screen and (max-width: 768px) {
  .section__image {
    aspect-ratio: 3 / 2;
  }
}

.hero {
  padding: 0;
}

.hero__inner {
  padding: calc(var(--header-height)  + 64px) 0 3.75rem;
  min-height: 764px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 2;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-inline: auto;
  display: flex;
  position: relative;
}

.hero--home .hero__content {
  max-width: 1024px;
  width: 100%;
}

.hero--home .hero__content .subtitle {
  width: 55%;
}

.hero--request .hero__inner {
  min-height: 600px;
  padding: 0;
}

.hero--request .hero__content {
  gap: 0;
  padding-bottom: 40px;
}

.hero--request .hero__content h1 {
  font-family: var(--font-family-primary);
  font-size: clamp(20px, 2vi, 28px);
  line-height: 1;
  font-weight: var(--fw-500);
  margin-bottom: 30px;
}

.hero--request .hero__content p {
  font-weight: var(--fw-500);
  color: var(--color-white);
}

.hero--page .hero__content .subtitle {
  width: 45%;
}

.hero--guide .hero__content {
  width: 90%;
}

@media only screen and (max-width: 992px) {
  .hero__inner, .hero--request .hero__inner {
    min-height: 480px;
  }

  .hero--request .hero__inner {
    padding: 32px 0;
  }

  .hero--request .hero__content {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .hero__content .subtitle, .hero--page .hero__content .subtitle {
    width: 100%;
  }
}

.card__items {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: clamp(24px, 3.4vw, 48px) clamp(12px, 1.7vw, 24px);
  display: grid;
}

.card__item {
  background-color: var(--color-white);
  border-radius: var(--radius-l);
  box-shadow: var(--shadow-primary);
}

.card__image {
  border-top-left-radius: var(--radius-l);
  border-top-right-radius: var(--radius-l);
  border-bottom-left-radius: var(--radius-m);
  border-bottom-right-radius: var(--radius-m);
  overflow: hidden;
}

.card__image img {
  width: 100%;
}

.card__content {
  padding: 24px clamp(12px, 1.7vw, 24px);
}

.card__content h3 {
  color: var(--color-green-400);
  margin-bottom: 6px;
}

.card__content .accent {
  margin-bottom: 16px;
}

.card__text > p {
  margin-bottom: 15px;
}

.card__text ul {
  padding-left: 24px;
}

.card__text li {
  list-style: disc;
  display: list-item;
}

.card__text li::marker {
  font-size: 10px;
}

.card__text li:not(:last-of-type) {
  margin-bottom: 8px;
}

.card--three .card__items {
  grid-template-columns: repeat(3, 1fr);
}

.card--small .card__items {
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(12px, 1.7vw, 22px) clamp(24px, 3.4vw, 48px);
}

.card--small .card__item {
  border-radius: var(--radius-m);
}

.card--small .card__content {
  padding: 16px clamp(12px, 1.7vw, 24px);
}

.card--small .card__content h3 {
  margin-bottom: 18px;
}

.card--welcome .card__items {
  margin-bottom: 24px;
}

.card--btn .card__item {
  padding-bottom: 78px;
  position: relative;
}

.card--btn .card__content {
  padding-bottom: 0;
}

.card--btn .card__content [class].btn {
  width: 89%;
  border-radius: var(--radius-s);
  position: absolute;
  bottom: 22px;
}

@media only screen and (max-width: 992px) {
  .card__image img {
    aspect-ratio: 1 / 1;
  }
}

@media only screen and (max-width: 768px) {
  .card__items {
    grid-template-columns: repeat(2, 1fr);
  }

  .card--three .card__items {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  .card--three .card__item:last-of-type {
    grid-column: span 2;
  }

  .card--three .card__item:last-of-type img {
    aspect-ratio: 4 / 2;
  }
}

@media only screen and (max-width: 576px) {
  .card__items {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .card__image img {
    aspect-ratio: 4 / 2;
  }

  .card--three .card__items, .card--small .card__items {
    grid-template-columns: 1fr;
  }

  .card--three .card__item:last-of-type {
    grid-column: auto;
  }

  .card--btn .card__item {
    padding-bottom: 24px;
  }

  .card--btn .card__content [class].btn {
    width: 100%;
    position: static;
  }

  .card--btn .card__text {
    margin-bottom: 24px;
  }
}

.box__hero, .box__intro {
  margin-bottom: 24px;
}

.box__hero {
  position: relative;
}

.box__content {
  z-index: 2;
  position: absolute;
  bottom: 30px;
  left: 36px;
}

.box__content h3, .box__content p {
  color: var(--color-white);
}

.box__content h3 {
  font-size: clamp(20px, 2.3vw, 32px);
}

.box__content p {
  font-size: clamp(14px, 1.7vw, 18px);
  font-weight: var(--fw-500);
}

.box__image {
  width: 100%;
  border-radius: var(--radius-m);
  overflow: hidden;
}

.box__image img {
  height: 100%;
  width: 100%;
}

.box__flex {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

.box__flex--reverse {
  flex-direction: row-reverse;
}

.box__flex:not(:last-of-type) {
  margin-bottom: 24px;
}

.box__desc {
  max-width: 425px;
  width: 100%;
}

.box__desc .accent {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 1.5;
}

.box__desc p {
  color: var(--color-green-500);
  opacity: .6;
}

@media only screen and (max-width: 768px) {
  .box__flex {
    flex-direction: column;
  }

  .box__desc {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .box__image img {
    aspect-ratio: 3 / 2;
  }

  .box__content {
    bottom: 16px;
    left: 16px;
  }
}

.text h2 {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-600);
  font-size: clamp(22px, 2.3vw, 32px);
}

.text h3:not(.accent), .text p {
  color: var(--color-green-500);
  opacity: .8;
}

.text__wrapper {
  max-width: 1091px;
  width: 100%;
  margin-inline: auto;
}

.text__wrapper > :not(:last-child) {
  margin-bottom: 24px;
}

.text__image {
  border-radius: var(--radius-m);
  overflow: hidden;
}

.text__image img {
  width: 100%;
}

.text__desc {
  text-align: center;
}

.text__grid {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(12px, 1.7vw, 24px);
  display: grid;
}

.text__items {
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.text__item h3 {
  margin-bottom: 16px;
}

.text__flex {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.text__flex--reverse {
  flex-direction: row-reverse;
}

.text__content > :not(:last-child, h2) {
  margin-bottom: 24px;
}

.text__content h2 {
  margin-bottom: clamp(24px, 2.5vw, 36px);
}

.text ul:not([class]) {
  padding-left: 24px;
}

.text li:not([class]) {
  list-style: disc;
  display: list-item;
}

.text li:not([class])::marker {
  font-size: 10px;
}

.text li:not([class]):not(:last-of-type) {
  margin-bottom: 8px;
}

.text--list .text__content ul {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 768px) {
  .text__grid {
    grid-template-columns: 1fr;
  }

  .text__flex {
    flex-direction: column;
  }

  .text__image {
    width: 100%;
  }

  .text__image img {
    aspect-ratio: 4 / 2;
  }

  .text--list .text__content ul {
    grid-template-columns: 1fr;
  }
}

.slider {
  overflow: hidden;
}

.slider__inner {
  position: relative;
}

.slider__slides {
  max-width: 95vw;
  overflow: visible;
}

.slider__items {
  max-width: inherit;
}

.slider__item {
  max-width: inherit;
  min-height: 616px;
  height: auto;
  border-radius: var(--radius-l);
  align-items: self-end;
  padding: clamp(12px, 1.7vw, 24px);
  display: flex;
  position: relative;
  overflow: hidden;
}

.slider__item:not(.swiper-slide-active) {
  opacity: 0;
}

.slider__image {
  height: 100%;
  position: absolute;
  inset: 0;
}

.slider__content {
  z-index: 1;
  max-width: 625px;
  width: 100%;
  background-color: var(--color-white-430);
  border-radius: var(--radius-l);
  padding: 16px clamp(24px, 3.4vw, 48px);
  position: relative;
}

.slider__content:before {
  content: "";
  border-radius: inherit;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: -1;
  position: absolute;
  inset: 0;
}

.slider__content h3, .slider__content p {
  color: var(--color-white);
}

.slider__content h3 {
  font-size: clamp(18px, 1.4vw, 20px);
  font-weight: var(--fw-700);
  margin-bottom: 4px;
}

.slider__content p {
  font-size: 14px;
  font-weight: var(--fw-500);
  line-height: 1.43;
}

.slider__buttons {
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

.slider__button {
  z-index: 4;
  width: 36px;
  height: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-white-80);
  border: 1px solid var(--color-grey-416);
  border-radius: var(--radius-full);
  background-image: url("../images/icons/arrow-icon.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider__button--next {
  right: -18px;
  transform: translateY(-50%)rotate(180deg);
}

.slider__button--prev {
  left: -18px;
}

.slider__button.swiper-button-disabled {
  opacity: .25;
  border-color: var(--color-dark-60);
}

@media only screen and (max-width: 1280px) {
  .slider__button--prev {
    left: 24px;
  }

  .slider__button--next {
    right: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .slider__item {
    min-height: 400px;
  }

  .slider__button {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .slider__items {
    margin-bottom: 24px;
  }

  .slider__item {
    align-items: center;
  }

  .slider__content {
    margin-inline: auto;
  }

  .slider__button {
    position: static;
    transform: translateY(0);
  }

  .slider__button--next {
    transform: rotate(180deg);
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.tabs__nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tabs__nav li:not(:last-of-type) {
  margin-right: 16px;
}

.tabs__btn {
  cursor: pointer;
}

.tabs__wrapper {
  max-width: 1201px;
  width: 100%;
  gap: 24px;
  display: flex;
}

.tabs--vertical .tabs__nav {
  max-width: 311px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.tabs--vertical .tabs__nav li:not(:last-of-type) {
  margin-right: 0;
}

.tabs--vertical .tabs__btn {
  color: var(--color-grey-400);
  font-size: 18px;
  line-height: 1.11;
  font-weight: var(--fw-500);
  border-bottom: 2px solid #0000;
  transition-property: color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
}

.tabs--vertical .tabs__btn.selected {
  font-size: 20px;
  font-weight: var(--fw-700);
  color: var(--color-red-400);
}

.tabs--vertical .tabs__btn:not(.selected):hover {
  color: var(--color-red-400);
  border-color: var(--color-red-400);
}

.tabs--vertical .tabs__pane {
  max-width: 866px;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .tabs__nav {
    flex-wrap: wrap;
    gap: 24px;
  }

  .tabs__wrapper {
    flex-direction: column;
  }

  .tabs--vertical .tabs__nav {
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
  }

  .tabs--vertical .tabs__pane {
    max-width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .tabs__nav {
    flex-direction: column;
  }
}

.policy h1 {
  color: var(--color-red-400);
  margin-bottom: clamp(24px, 3.4vw, 48px);
  font-size: clamp(26px, 2.8vi, 40px);
  line-height: 1.42;
}

.policy h2 {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-600);
  margin-bottom: 24px;
  font-size: clamp(22px, 2.3vw, 32px);
}

.policy .policy__content:not(:last-child) {
  margin-bottom: clamp(24px, 3.4vw, 48px);
}

.policy .policy__content > :not(:last-child, h2) {
  margin-bottom: clamp(24px, 3vw, 32px);
}

.policy ul {
  padding-left: clamp(16px, 3vw, 32px);
}

.policy li {
  padding-left: 32px;
  position: relative;
}

.policy li:before {
  content: "✔";
  position: absolute;
  left: 0;
}

.policy li:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  color: var(--color-red-400);
}

.policy strong {
  font-size: clamp(18px, 3vw, 22px);
  font-weight: var(--fw-500);
}

.footer {
  background-color: var(--color-green-400);
  padding: clamp(24px, 2.8vw, 40px) 0;
}

.footer__wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__mail {
  padding-left: 32px;
  position: relative;
}

.footer__mail:before {
  content: "";
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate() invert() sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
  background-image: url("../images/icons/mail-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 768px) {
  .footer__wrapper {
    flex-direction: column;
    gap: 24px;
  }
}

/*# sourceMappingURL=main.css.map */
