@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    margin-right: rem(36);
    max-width: 306px;
    width: 100%;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 612px;
    width: 100%;

    @include media(tablet) {
      position: fixed;
      left: -1px;
      top: calc(0px - var(--header-height));
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: calc(70px + (var(--header-height) * 1)) 16px 30px;
      max-width: unset;
      width: calc(100% + 1px);
      height: calc(var(--vh) + 24px);
      background-color: var(--color-green-400);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: clamp(12px, 1.7vw, 24px);
    }
  }

  &__link {
    font-size: 18px;
    font-weight: var(--fw-500);
    line-height: 1.5;
    letter-spacing: 0;
    color: var(--color-white);
    text-transform: capitalize;
    transition: border-bottom 0.3s ease-in-out;

    &.true,
    &:hover {
      border-bottom: 2px solid var(--color-white);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 16px;

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &:not(&--footer) {
    .logo {
      @include media(tablet) {
        max-width: 240px;
      }
    }

    .nav__list {
      margin: 0 rem(36) 0 0;

      @include media(tablet) {
        z-index: -1;
        flex-direction: column;
        margin-right: 0;
      }
    }

    .nav__item {
      &:not(:last-of-type) {
        margin-right: rem(36);
        @include media(tablet) {
          margin-right: 0;
        }
      }

      @include media(tablet) {
        margin-bottom: 30px;
      }
    }
  }

  &--footer {
    margin-bottom: clamp(24px, 3.4vw, 48px);

    @include media(tablet) {
      .logo {
        max-width: 180px;
        margin-right: 24px;
      }
    }

    @include media(mobile-l) {
      flex-direction: column;
      gap: 24px;

      .logo {
        max-width: 260px;
      }
    }

    @include media(mobile-m) {
      .nav__list {
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;
      }
    }
  }
}