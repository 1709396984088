@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.box {
  &__hero,
  &__intro {
    margin-bottom: 24px;
  }

  &__hero {
    position: relative;
  }

  &__content {
    position: absolute;
    left: 36px;
    bottom: 30px;
    z-index: 2;

    h3,
    p {
      color: var(--color-white);
    }

    h3 {
      font-size: clamp(20px, 2.3vw, 32px);
    }

    p {
      font-size: clamp(14px, 1.7vw, 18px);
      font-weight: var(--fw-500);
    }
  }

  &__image {
    width: 100%;
    border-radius: var(--radius-m);
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__flex {
    @include flex-all-sb;
    gap: 16px;

    &--reverse {
      flex-direction: row-reverse;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  &__desc {
    max-width: 425px;
    width: 100%;

    .accent {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 1.5;
    }

    p {
      color: var(--color-green-500);
      opacity: 0.6;
    }
  }

  @include media(mobile-l) {
    &__flex {
      flex-direction: column;
    }

    &__desc {
      max-width: 100%;
    }
  }

  @include media(mobile-m) {
    &__image {
      img {
        aspect-ratio: 3/2;
      }
    }

    &__content {
      bottom: 16px;
      left: 16px;
    }
  }
}